/**
 * Internal dependencies.
 */
import fetch from 'isomorphic-fetch';
import { ApolloClient, createHttpLink, InMemoryCache, ApolloLink } from '@apollo/client';
import { isEmpty } from 'lodash';
import { asyncMap } from '@apollo/client/utilities';

// if ( process.browser ) {
// 	console.warn( 'hey', process.env.GATSBY_WORDPRESS_SITE_URL );
// }

/**
 * Middleware operation
 *
 * If we have a session token in localStorage, add it to the GraphQL request as a Session header.
 * If we have a auth token in localStorage, add it to the GraphQL request as a authorization header.
 */

const middleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  const auth = typeof window !== 'undefined' ? JSON.parse(localStorage.getItem('auth')) : null;
  const token = !isEmpty(auth) ? auth.authToken : null;
  const session = typeof window !== 'undefined' ? localStorage.getItem('woo-session') : null;

  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
      'woocommerce-session': session ? `Session ${session}` : null,
    },
  }));

  return forward(operation);
});

/**
 * Afterware operation.
 *
 * This catches the incoming session token and stores it in localStorage, for future GraphQL requests.
 */
export const afterware = new ApolloLink((operation, forward) => {
  return asyncMap(forward(operation), async (response) => {
    /**
     * Check for session header and update session in local storage accordingly.
     */
    const context = operation.getContext();
    const {
      response: { headers },
    } = context;
    const session = headers.get('woocommerce-session');

    if (session) {
      // Remove session data if session destroyed.
      if ('false' === session) {
        localStorage.removeItem('woo-session');

        // Update session new data if changed.
      } else if (localStorage.getItem('woo-session') !== session) {
        localStorage.setItem('woo-session', headers.get('woocommerce-session'));
      }
    }

    return response;
  });
});

// Apollo GraphQL client.
export const client = new ApolloClient({
  link: afterware.concat(
    middleware.concat(
      createHttpLink({
        uri: `${process.env.GATSBY_WP_URL}/graphql`,
        fetch: fetch,
      })
    )
  ),
  cache: new InMemoryCache({
    resultCaching: false,
  }),
});
